.apexcharts-tooltip {
    /* position: relative;
    padding: 10px;
    background: #2a2a2a;
    color: #fff;
    border-radius: 5px;
    width: 40%; */
    display: none !important;
}

.apexcharts-tooltip-title {
    font-weight: bold;
    text-align: left;
}

.apexcharts-tooltip-series-group {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.apexcharts-tooltip-marker {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.apexcharts-tooltip-text {
    display: flex;
    flex-direction: column;
}

.apexcharts-tooltip-text-y-label {
    font-weight: bold;
}

.apexcharts-tooltip-text-y-value {
    margin-left: 5px;
}

#tooltip {
    position: absolute;
    display: none;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    pointer-events: none; /* Prevent tooltip from interfering with mouse events */
    z-index: 1000;
}
