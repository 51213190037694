/* styles.css */

body {
    background-color: #272b2d;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    padding: 0 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #1e344c;
    padding: 8px;
    text-align: right;
}

th {
    background-color: #1e344c;
    font-weight: bold;
}

th.sortable {
    cursor: pointer;
    text-decoration: underline;
}

th.sorted.asc:after {
    content: ' ▲';
}

th.sorted.desc:after {
    content: ' ▼';
}

tr:nth-child(odd) {
    background-color: #333333;
}

tr:nth-child(even) {
    background-color: #272b2d;
}

tr.row-expanded {
    background: inherit;
}

tr.row-expanded ~ tr:nth-child(odd) {
    background-color: #272b2d;
}

tr.row-expanded ~ tr:nth-child(even) {
    background-color: #333333;
}

@keyframes fade-from-up {
    from {
        background-color: #00ff00;
    }
}

@keyframes fade-from-down {
    from {
        background-color: #ff0000;
    }
}

.animation-up {
    animation: fade-from-up 1s forwards;
}

.animation-down {
    animation: fade-from-down 1s forwards;
}

#title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
}

.expand-icon {
    user-select: none;
    text-align: center;
}

.row-expanded {
    background-color: #3a3f44;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}

.row-expanded td {
    padding: 15px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

#refresh-timer {
    text-align: left;
}

button {
    text-align: right;
    background-color: #1e344c;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
}

button:hover {
    background-color: #33475b;
}

.name-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.expand-icon {
    margin-right: 10px;
    flex-shrink: 0;
}

.content {
    flex-grow: 1;
    text-align: right;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

a:hover {
    text-decoration: underline;
}
