nav {
    background-color: #333;
    padding: 10px;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative; /* Ensure the items can be positioned absolutely */
}

nav ul li {
    margin-right: 20px;
}

nav ul li.support-me {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

nav ul li.support-me a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

nav ul li.support-me a:hover {
    text-decoration: underline;
}

nav ul li.logout {
    margin-left: auto;
    margin-right: 0; /* Remove margin-right for the last child */
}

nav ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

nav ul li a:hover {
    text-decoration: underline;
}

nav ul li button {
    background-color: #1e344c;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
}

nav ul li button:hover {
    background-color: #33475b;
}
