.expanded-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    text-align: left;
}

.expanded-content p,
.expanded-content h4 {
    margin: 0;
}

.full-width {
    flex: 1 1 100%;
}

.half-width {
    flex: 1 1 48%;
}

.news-section {
    flex: 1 1 100%;
    margin-top: 10px;
}

.news-item {
    margin-bottom: 10px;
}
